
import Vue from "vue";
import IAccountPlanImporterOrigin from "@/lib/interfaces/account-plan-importer-origin";
import ImportAccountPlansOriginsList from "@/components/subviews/import-account-plans-origins/import-account-plans-origins-list.vue";
import ImportAccountPlansOriginsMaintenance from "@/components/subviews/import-account-plans-origins/import-account-plans-origins-maintenance.vue";

export default Vue.extend({
  name: "ImportAccountPlansOrigins",

  components: {
    ImportAccountPlansOriginsList,
    ImportAccountPlansOriginsMaintenance,
  },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      importAccountPlanOrigin: {} as IAccountPlanImporterOrigin,
    };
  },
});
