
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";
import IAppAccountPlanImporterOrigin from "@/lib/interfaces/app-account-plan-importer-origin";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IAppImporterOrigin from "@/lib/interfaces/app-importer-origin";
import IAccountPlanImporterOrigin from "@/lib/interfaces/account-plan-importer-origin";
import { capitalize } from "@/lib/a-format";

interface IAppImporterOriginExtended extends IAppImporterOrigin {
  aplicacao: string;
  nome_raz: string;
}

interface IAccountPlanImporterOriginExtended
  extends IAccountPlanImporterOrigin {
  plano_conta: string;
  plano_conta_hierarquia: string;
}

export default Vue.extend({
  name: "AppAccountPlansImportersOriginsMaintenance",

  components: {
    ABtn,
    ACard,
    ATextField,
    vAlertMessage,
    ABtnDialogConfirm,
    AAutocomplete,
  },

  props: {
    appAccountPlanImporterOrigin: {
      type: Object as PropType<IAppAccountPlanImporterOrigin>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataAppAccountPlanImporterOrigin: {
        id_aplicacao_importador_origem: "",
        id_plano_conta_importador_origem: new Array<string>(),
      },

      currentAppAccountPlansImportersOrigins:
        new Array<IAppAccountPlanImporterOrigin>(),
      appImportersOrigins: new Array<IAppImporterOriginExtended>(),
      accountPlansImportersOrigins:
        new Array<IAccountPlanImporterOriginExtended>(),
    };
  },

  watch: {
    appAccountPlanImporterOrigin: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";

        this.dataAppAccountPlanImporterOrigin = {
          id_aplicacao_importador_origem:
            this.appAccountPlanImporterOrigin.id_aplicacao_importador_origem,
          id_plano_conta_importador_origem: [],
        };

        this.getAppAccountPlansImportersOrigins().then();
      },
    },

    "dataAppAccountPlanImporterOrigin.id_aplicacao_importador_origem"() {
      if (this.dataAppAccountPlanImporterOrigin.id_aplicacao_importador_origem)
        this.getAppAccountPlansImportersOrigins().then();
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cAppImportersOrigins(): Record<string, unknown>[] {
      return this.appImportersOrigins.map((item) => ({
        ...item,
        text: `${item.aplicacao} - ${item.ndatabase} - ${item.regiao_origem} ${item.nregiao} - ${item.nome_raz}`,
      }));
    },

    cAccountPlansImportersOrigins(): Record<string, unknown>[] {
      return this.accountPlansImportersOrigins.map((item) => ({
        ...item,
        text: `${item.plano_conta_hierarquia} ${
          item.plano_conta
        } - ${capitalize(item.identificador)}`,
      }));
    },
  },

  mounted() {
    this.getAppImportersOrigins().then();
    this.getAccountPlansImportersOrigins().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_plano_conta_importador_origem.write");
        const tblAppPlanoContaImportadorOrigem = new Table(
          "aplicacao_plano_conta_importador_origem"
        );

        const itensDesativados = this.currentAppAccountPlansImportersOrigins
          .filter(
            (item) =>
              !this.dataAppAccountPlanImporterOrigin.id_plano_conta_importador_origem.includes(
                item.id_plano_conta_importador_origem
              )
          )
          .map((item) => ({
            id_aplicacao_importador_origem: item.id_aplicacao_importador_origem,
            id_plano_conta_importador_origem:
              item.id_plano_conta_importador_origem,
            desativado: "1",
          }));

        const itensNovos =
          this.dataAppAccountPlanImporterOrigin.id_plano_conta_importador_origem
            .filter(
              (id_plano_conta_importador_origem) =>
                !itensDesativados.some(
                  (item) =>
                    item.id_plano_conta_importador_origem ===
                    id_plano_conta_importador_origem
                )
            )
            .map((id_plano_conta_importador_origem) => {
              return {
                id_aplicacao_importador_origem:
                  this.dataAppAccountPlanImporterOrigin
                    .id_aplicacao_importador_origem,
                id_plano_conta_importador_origem,
                desativado: "0",
              };
            });

        const items = [...itensDesativados, ...itensNovos];

        if (items.length > 0) {
          tblAppPlanoContaImportadorOrigem.setColsFromObject(items[0]);
          tblAppPlanoContaImportadorOrigem.addRowsFromObject(...items);
        }

        rq.addTable(tblAppPlanoContaImportadorOrigem);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma aplicação com esses dados";
          return;
        }

        this.ret_msg = "Vínculo atualizado";

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataAppAccountPlanImporterOrigin = {
        id_aplicacao_importador_origem: "",
        id_plano_conta_importador_origem: [],
      };

      this.form.resetValidation();
    },

    async getAppImportersOrigins() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_importador_origem.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.appImportersOrigins =
          rsp.getTable("aplicacao_importador_origem")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAccountPlansImportersOrigins() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_conta_importador_origem.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlansImportersOrigins =
          rsp.getTable("plano_conta_importador_origem")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAppAccountPlansImportersOrigins() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_plano_conta_importador_origem.read", {
          idAppImportadorOrigem:
            this.dataAppAccountPlanImporterOrigin
              .id_aplicacao_importador_origem,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.currentAppAccountPlansImportersOrigins =
          rsp
            .getTable("aplicacao_plano_conta_importador_origem")
            ?.getRowsObject() ?? [];

        this.dataAppAccountPlanImporterOrigin.id_plano_conta_importador_origem =
          this.currentAppAccountPlansImportersOrigins.map(
            (item) => item.id_plano_conta_importador_origem
          );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
