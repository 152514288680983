
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";
import ASelect from "@/components/elements/a-select.vue";
import IAccountPlanImporterOrigin from "@/lib/interfaces/account-plan-importer-origin";
import IAccountPlan from "@/lib/interfaces/account-plan";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IContaImporte from "@/lib/interfaces/conta-importe";

export default Vue.extend({
  name: "ImportAccountPlansOriginsMaintenance",

  components: {
    ABtn,
    ASwitch,
    ACard,
    ATextField,
    vAlertMessage,
    ABtnDialogConfirm,
    ASelect,
    AAutocomplete,
  },

  props: {
    importAccountPlanOrigin: {
      type: Object as PropType<IAccountPlanImporterOrigin>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      accountPlans: new Array<IAccountPlan>(),

      dataImportAccountPlanOrigin: {
        id: "",
        id_plano_conta: "",
        identificador: "VENDA",
        desativado: "0",
      } as IAccountPlanImporterOrigin,

      contasImporte: new Array<IContaImporte>(),
    };
  },

  watch: {
    importAccountPlanOrigin: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataImportAccountPlanOrigin = {
          ...this.importAccountPlanOrigin,
        };
      },
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataImportAccountPlanOrigin.id ? "Alterar" : "Salvar";
    },

    cAccountPlans(): (IAccountPlan & { text: string })[] {
      return this.accountPlans.map((accountPlan) => ({
        ...accountPlan,
        text: `${accountPlan.nivel} ${accountPlan.descricao}`,
      }));
    },
  },

  mounted() {
    this.getAccountPlans().then();
    this.getContasImporte().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_conta_importador_origem.write");
        const tblCusto = new Table("plano_conta_importador_origem");

        const obj = {
          id: this.dataImportAccountPlanOrigin.id || "default",
          id_plano_conta: this.dataImportAccountPlanOrigin.id_plano_conta,
          identificador: this.dataImportAccountPlanOrigin.identificador,
          desativado: this.dataImportAccountPlanOrigin.desativado,
        };
        tblCusto.setColsFromObject(obj);
        tblCusto.addRowsFromObject(obj);

        rq.addTable(tblCusto);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Este identificador já está sendo usado";
          return;
        }

        this.ret_msg = `Valor ${
          this.dataImportAccountPlanOrigin.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataImportAccountPlanOrigin.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataImportAccountPlanOrigin = {
        id: "",
        id_plano_conta: "",
        identificador: "VENDA",
        desativado: "0",
      };

      this.form.resetValidation();
    },

    async getAccountPlans() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read", {
          sortBy: "nivel",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlans = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getContasImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.contasImporte =
          rsp.getTable("conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
