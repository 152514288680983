
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import IAccountPlanImporterOrigin from "@/lib/interfaces/account-plan-importer-origin";
import IAccountPlan from "@/lib/interfaces/account-plan";
import IContaImporte from "@/lib/interfaces/conta-importe";

export default Vue.extend({
  name: "ImportAccountPlanOriginList",

  components: {
    ABtn,
    ACard,
    ADataTable,
    ATextField,
    vAlertMessage,
    ASelectStatus,
  },

  props: {
    importAccountPlanOrigin: {
      type: Object as PropType<IAccountPlanImporterOrigin>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      importAccountPlansOrigins: new Array<IAccountPlanImporterOrigin>(),
      accountPlans: new Array<IAccountPlan>(),

      headers: [
        {
          text: "Plano de conta",
          value: "cAccountPlan",
          sortable: false,
          align: "left",
        },
        { text: "Identificador", value: "cIdentificador", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "right" },
      ],

      contasImporte: new Array<IContaImporte>(),
    };
  },

  watch: {
    forceRefresh() {
      this.getImportAccountPlansOrigins().then();
    },

    options: {
      deep: true,
      handler() {
        this.getImportAccountPlansOrigins().then();
      },
    },
  },

  computed: {
    cImportAccountPlansOrigins(): Array<Record<string, unknown>> {
      return this.importAccountPlansOrigins.map((item) => ({
        ...item,

        cIdentificador:
          this.contasImporte.find((ci) => ci.id === item.identificador)
            ?.descricao ?? item.identificador,

        cAccountPlan:
          this.accountPlans.find((ap) => ap.id === item.id_plano_conta)
            ?.descricao ?? item.id_plano_conta,
        cDesativado: item.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  mounted() {
    this.getAccountPlans().then();
    this.getContasImporte().then();
  },

  methods: {
    async openImportAccountPlanOrigin(id: string) {
      const importAccountPlanOrigin = this.importAccountPlansOrigins.find(
        (importAccountPlanOrigin) => importAccountPlanOrigin.id === id
      );
      this.$emit("update:import-account-plan-origin", {
        ...importAccountPlanOrigin,
      });
    },

    async getAccountPlans() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read", {
          sortBy: "nivel",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlans = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getContasImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_importe.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.contasImporte =
          rsp.getTable("conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getImportAccountPlansOrigins() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_conta_importador_origem.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.importAccountPlansOrigins =
          rsp.getTable("plano_conta_importador_origem")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
