
import Vue from "vue";
import IAppAccountPlanImporterOrigin from "@/lib/interfaces/app-account-plan-importer-origin";
import AppAccountPlansImportersOriginsList from "@/components/subviews/app-account-plans-importers-origins/app-account-plans-importers-origins-list.vue";
import AppAccountPlansImportersOriginsMaintenance from "@/components/subviews/app-account-plans-importers-origins/app-account-plans-importers-origins-maintenance.vue";

export default Vue.extend({
  name: "AppAccountPlansImportersOrigins",

  components: {
    AppAccountPlansImportersOriginsList,
    AppAccountPlansImportersOriginsMaintenance,
  },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      appAccountPlanImporterOrigin: {} as IAppAccountPlanImporterOrigin,
    };
  },
});
