
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import IAppAccountPlanImporterOrigin from "@/lib/interfaces/app-account-plan-importer-origin";

interface IAppAccountPlanImporterOriginExtended
  extends IAppAccountPlanImporterOrigin {
  id_regiao_origem: string;
  setor_origem: string;
  nregiao_origem: string;
  aplicacao: string;
  nome_raz: string;
  plano_conta_descricao: string;
  plano_conta_hierarquia: string;
}

export default Vue.extend({
  name: "AppAccountPlansImportersOriginsList",

  components: {
    ABtn,
    ACard,
    ADataTable,
    ATextField,
    vAlertMessage,
  },

  props: {
    appAccountPlanImporterOrigin: {
      type: Object as PropType<IAppAccountPlanImporterOrigin>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",

      options: {} as DataOptions,
      appAccountPlansImportersOrigins:
        new Array<IAppAccountPlanImporterOriginExtended>(),

      headers: [
        { text: "Aplicação", value: "aplicacao", sortable: false },
        {
          text: "Centro de custo",
          value: "centro_custo_descricao",
          sortable: false,
        },
        { text: "Setor", value: "setor_origem", sortable: false },
        { text: "Região", value: "cRegiao", sortable: false },
        { text: "Empresa", value: "nome_raz", sortable: false },
        { text: "Plano de conta", value: "cPlanoConta", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAppAccountPlansImportersOrigins().then();
    },

    options: {
      deep: true,
      handler() {
        this.getAppAccountPlansImportersOrigins().then();
      },
    },
  },

  computed: {
    cAppAccountPlansImportersOrigins(): Array<Record<string, unknown>> {
      return this.appAccountPlansImportersOrigins.map((item) => ({
        ...item,
        cRegiao: `${item.id_regiao_origem} - ${item.nregiao_origem}`,
        cPlanoConta: `${item.plano_conta_hierarquia} ${item.plano_conta_descricao}`,
      }));
    },
  },

  methods: {
    async openAppAccountPlanImporterOrigin(id: string) {
      const appAccountPlanImporterOrigin =
        this.appAccountPlansImportersOrigins.find(
          (appAccountPlanImporterOrigin) =>
            appAccountPlanImporterOrigin.id === id
        );
      this.$emit("update:app-account-plan-importer-origin", {
        ...appAccountPlanImporterOrigin,
      });
    },

    async getAppAccountPlansImportersOrigins() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("aplicacao_plano_conta_importador_origem.read", {
          search: this.search,
          desativado: "0",
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.appAccountPlansImportersOrigins =
          rsp
            .getTable("aplicacao_plano_conta_importador_origem")
            ?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
